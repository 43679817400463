import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>It doesn't look like that link points anywhere.</p>
    <p>Interested in checking out LogRocket? <a href="https://logrocket.com"></a></p>
  </Layout>
)

export default NotFoundPage
